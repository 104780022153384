/* src/styles/ProgressBarStyles.css */

.progress-bar-container {
    position: absolute;
    top: 85%;
    left: 90%;
    transform: translate(-50%, -50%) scale(1); /* Ensure scale is set to 1 by default */
    text-align: center;
    background-color: white;
    width: 60px;
    height: 60px;
    letter-spacing: 2px;
    border-radius: 70px;
    font-family: 'Gagalin', sans-serif; /* Ensure the font is loaded */
    z-index: 10000;
    transition: transform 0.3s ease; /* Smooth transition for both scaling up and down */
}

.progress-bar-container:hover {
    transform: translate(-50%, -50%) scale(1.5); /* Scale to 1.5 when hovered, adjust as needed */
}
