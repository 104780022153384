.cardslider-container {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    
}

.card-slider-card {
    margin: 0 auto;
    border-radius: 20px;
    height: 40%;
    /*background-color: darkorange;*/

}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /*background: rgba(0, 0, 0, 0.0);*/
    border-radius: 30px;
    /*background-color: darkorange;*/
    background-color: rgba(218, 245, 255, 0.3);
    border: 5px solid #DAF5FF;
    margin-bottom: 20px;

}

.card img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 20px;
}




.slide-indicators {
    display: flex;
    justify-content: center;
    margin-top: -30px;
    
}

.slide-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: grey;
    margin: 0 5px;
    cursor: pointer;
}

.slide-indicator.active {
    background-color: greenyellow;
}

.next-button {
    margin-top: 10px;
}

.cardslider-button {
    background-color: #ffd289;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 10px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 30px 0px #091E3B;
    -moz-box-shadow: 0px 0px 30px 0px #091E3B;
    box-shadow: 0px 0px 30px 0px #091E3B;
}

.cardslider-button:hover {
    background-color: yellowgreen;
}



.loading-button {
    background-color: lightcyan;
    color: #ffd289;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 10px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 30px 0px #091E3B;
    -moz-box-shadow: 0px 0px 30px 0px #091E3B;
    box-shadow: 0px 0px 30px 0px #091E3B;
}

.loading-button:hover {
    background-color: lightcyan;
}


