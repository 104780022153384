.custom-button {
    transition: background-color 0.3s ease; /* Optional: Smooth transition for the button's background */
    border-radius: 20px;
    height: 40px;
    display: inline-flex;          /* Use flexbox */
    justify-content: center;       /* Horizontally center */
    align-items: center;
}
  
  .custom-button:hover .custom-icon {
    fill: white; /* Change SVG color on button hover */
  }
  
.custom-icon {
    fill: blue; /* Default SVG color */
    transition: fill 0.3s ease; /* Smooth transition for SVG color change */
  }
