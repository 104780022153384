a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  /*box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);*/
}

.base-color{
  color: white;
  background-color: rgba(0,0,0,0.0);
  font-weight: bold ;
}



@media (min-width: 300px) {
  .navbar-custom {
    width: 100vw;

    z-index: 1;
    
    max-height: 100px;
    color: white;
    /*box-shadow: 0 6px 8px 0 rgba(0,0,0,.4);*/
    border: red;
    
  }
}

@media (min-width: 1400px) {
  .navbar-custom {
    width: 100vw;


    z-index: 1;
    
    max-height: 200px;
    color: white;
    /*box-shadow: 0 6px 8px 0 rgba(0,0,0,.4);*/
    border: red;
  }
}

.nav.navbar-nav.navbar-right li a {
  color: white;
}

.navbar-nav {
  color: white;

}

.brand-image{
  max-height: 200px;
  
}

@media (min-width: 300px) {
  .brand-image {
    max-height: 70px;
  }
}

@media (min-width: 760px) {
  .brand-image {
    max-height: 200px;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
color: red;
}

.navbar-light .navbar-toggler {
  background-color: white;
  border: 1px solid navy;
}

.navbar-dropdown {
  background-color: white;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;

}



