.footer {
  background-color: #111;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 40px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; 
}

/* Adjust layout of sections */
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  flex: 3;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
  flex: 1;
}

.footer-section {
  margin-right: 30px;
  min-width: 150px;
}

.footer-section h4 {
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #AEAEAE;
  color: #AEAEAE;

}

.social-icons a {
  color: #fff;
  /*margin-right: 20px;*/
  text-decoration: none;
  font-size: 18px;
}

.footer-quote {
  width: 50%;
  color: #AEAEAE;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

  .footer {
    text-align: center;
    align-items: center;
  }
  .footer-content {
    flex-direction: column;
  }

  .footer-quote {
    width: 100%;
  }

  .footer-right {
    flex-direction: column;
  }

  .footer-section {
    margin-right: 0; /* Removes right margin for stacked columns */
    margin-bottom: 20px; /* Adds bottom margin between sections */
  }

  .footer-bottom {
    flex-direction: column;
    gap: 10px;
  }

  .footer-left,
  .footer-right {
    width: 100%; /* Ensures sections take full width on smaller screens */
  }
}
