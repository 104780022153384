.home-container {
    text-align: center;
}

.home-button {
    background-color: #ffd289;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 20px;
    margin-bottom: 20px;

    -webkit-box-shadow: 0px 0px 30px 0px #091E3B;
    -moz-box-shadow: 0px 0px 30px 0px #091E3B;
    box-shadow: 0px 0px 30px 0px #091E3B;
}

.home-button:hover {
    background-color: yellowgreen;
}


.text-white {
    color: white;
}




/* Default styles for larger screens */
.book-container {
    display: flex;
    flex-direction: row;  /* Horizontal layout */
    flex-wrap: wrap;      /* Allows multiple lines */
    justify-content: flex-start; /* Aligns items to the start of the line */
    margin: 0.625em; /* 10px / 16 */
}

.book-item {
    flex: 1 1 33%; /* Each book takes up one-third of the line */
    max-width: 33%; /* Ensures no more than three books per line */
    box-sizing: border-box; /* Includes padding and border in the element's total width */
    padding: 0.625em; /* 10px / 16 */
}

/* Styles for smaller screens */
@media screen and (max-width: 768px) {
    .book-container {
        flex-direction: column;  /* Vertical layout */
        align-items: center;     /* Center align the items */
    }

    .book-item {
        max-width: 100%; /* Each book takes full width on small screens */
        flex-basis: 100%; /* Adjusts the base width to full width */
    }
}

/* Styles based on orientation */
@media screen and (orientation: portrait) {
    .book-container {
        flex-direction: column;
    }

    .book-item {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.book-button {
    width: 50%;  /* Forces the button to expand to the full width of its container */
    padding: 0.375em 1em; /* Adjust padding as needed (6px and 16px / 16) */
    box-sizing: border-box; /* Includes padding in the width calculation */
}

/* Ensure alignment and spacing are consistent */
.book-item {
    display: flex;
    flex-direction: column;  /* Stack children vertically */
    align-items: center;     /* Center-align children */
}

.home-read-button {
    width: 100%; /* Ensure the image container also takes full width if needed */
}
