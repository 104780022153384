

h4{
    color: white;
    margin-top: 30px;
    font-size: 32px;
    margin-bottom: 20px;
    font-family: "gagalin";
}

.form-content{
    
    text-align: center;
    border-radius: 30px;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;

    padding-bottom: 20px;
    /*background-color: whitesmoke;*/
    /*background-color: rgba(87, 108, 188, 0.5);*/
    background-color: rgba(218, 245, 255, 0.3);
    border: 5px solid #DAF5FF;

    margin-bottom: 10px;
    height: 70%;
}




label {
    display: inline-block;
    /*margin-right: 80px;*/

    margin-bottom: 10px;
}


.select-image{
    border-radius: 15px;
    width: 80%;
}

input[type=radio] {
    border: 5px solid greenyellow;
    display: block;
    margin: 0 auto;
}




input {
        border: 5px solid greenyellow;
    /*border: 5px solid #DD580E;*/
    border-radius: 30px;
}


.character-selector {
    color: white;
    text-align: center;
    margin-bottom: 10px;
}

.text-input {
    padding: 5px;
    font-family: Oswald;
    text-align: center;
}


.character-button {
    background-color: #ffd289;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 10px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 30px 0px #091E3B;
    -moz-box-shadow: 0px 0px 30px 0px #091E3B;
    box-shadow: 0px 0px 30px 0px #091E3B;
}

.character-button:hover {
    background-color: yellowgreen;
}

.button-container {
    text-align: center;
}
