/* src/styles/TurnDevicePopUp.css */

.popup-style {
    width: 90vw !important; /* Adjust the width as needed */
    max-width: 500px !important;
    padding: 20px !important;
    box-sizing: border-box;
}

.custom-title-class {
    /* Customize the title if you have one */
}

.custom-content-class {
    /* Customize the content area */
}

.confirm-button {
    background-color: #0074D9 !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 10px 20px !important;
    font-size: 1em !important;
    cursor: pointer !important;
}

.confirm-button:hover {
    background-color: #005bb5 !important;
}
