/* styles.css */

.character-avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.avatar-container {
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  background-color: floralwhite;
  border-width: 3px;
  /* Thick border */
  border-style: solid;
  border-color: #091E3B;
}



.avatar-image {
  width: 200%;
  height: auto;
  position: absolute;
  object-fit: cover;
  bottom: -110%;
  left: -53%;
  right: 0;
  border-width: 5px;
  border-color: #091E3B;
}



.avatar-label {
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  text-align: center;
}