/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.cdnfonts.com/css/gagalin');
@import url('https://fonts.cdnfonts.com/css/amaranth');
@import url('https://fonts.cdnfonts.com/css/agbalumo');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.cdnfonts.com/css/poetsen-one');
@import url('https://fonts.cdnfonts.com/css/blueberry');
@import url('https://fonts.cdnfonts.com/css/the-simple-one');
@import url('https://fonts.cdnfonts.com/css/clap-hand');
@import url('https://fonts.googleapis.com/css2?family=Twinkle+Star&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');






/* Colours */

.bg-home{
    background-color: #091E3B;
}

.bg-sand{
    background-color: #F3F1EC;
}

.bg-gold{
    background-color: #E0BB7F;
}

.form-colour{
    background-color: #FCF3E5;
}

.bg-aqua-green{
    background-color: #4DB1B5;
}

.bg-light-blue{
    background-color: #D9F1F2;
}

.bg-transparent {
    border-color: transparent;
}

.bg-green-yellow-gradient {
    background: linear-gradient(135deg, #CDFFD8, #94B9FF);
}

.bg-navy-yellow-gradient {
    background: linear-gradient(135deg, #091E3B, #CDFFD8);
}

.text-navy {
    color: #091E3B;
}

.text-red {
    color: #FF3131;
}









/*Containers*/
.feature-div-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    color: white;
    font-size: 2em;
    object-fit: cover;
    margin: 0px;
    padding: 0px;
}

@media (min-width: 768px) {
    .feature-div-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 95vh;
        color: white;
        font-size: 2em;
        object-fit: cover;
        margin: 0px;
        padding: 0px;
    }
}

.multi-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 2em;
    object-fit: cover;
    margin: 0px;
    padding: 0px;
    background-color: #F3F1EC;
    /*background-image: url('https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png');*/
    /*background-size: cover;*/
    /*background-position: center;*/
}


.landing-main-div-container {
    position: relative;
    justify-content: center;
    align-items: center;
    background-image: url('https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/hero_background.gif');
    background-size: cover;
    background-position: center;
    margin-top: -160px;
    width: 100vw;
}
@media (min-width: 1200px) {
    .landing-main-div-container {
        position: relative;
        justify-content: center;
        align-items: center;
        /*background-image: url('pi/images/new_website_landing.gif');*/
        background-image: url('https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/hero_background.gif');
        background-size: cover;
        background-position: center;
        margin-top: -160px;
        width: 100vw;
    }
}

.form-container {
    max-height: 80vh; /* Adjust this height to your needs */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    border-radius: 30px;
    border-width: 10px;
}

.landing-third-div-container {
    background-color: #F3F1EC;
    justify-content: center;
    align-items: center;
    background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/m_ bring_stories_life_landing.png");
    background-size: cover;
    background-position: top;
}

@media (min-width: 1200px) {
    .landing-third-div-container {
        background-color: #F3F1EC;
        justify-content: center;
        align-items: center;
        background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/bring_stories_landing.png");
        background-size: cover;
        background-position: center;
        width: 100vw;
    }
}


    .landing-secondary-div-container {
        background-color: #F3F1EC;
        justify-content: center;
        align-items: center;
        background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/m_ reimagine_landing.png");
        background-size: cover;
        background-position: top;
    }

    @media (min-width: 1200px) {
        .landing-secondary-div-container {
            background-color: #F3F1EC;
            justify-content: center;
            align-items: center;
            background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/reimagine_landing.png");
            background-size: cover;
            background-position: center;
            width: 100vw;
        }
}


.mobile-landing-product-range{
    height: 100%;
    background-color: #D9F1F2;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 1.5em;
    margin: 0px;
    padding: 0px;
}

.landing-product-range{
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 1.5em;
    margin: 0px;
    padding: 0px;
    background-color: #D9F1F2;
}

.google-form{
    margin: -30px;
    width: 100%;
    height: 1000px;
}

.full-width-container{
    width: 100%;
    height: 100%;
    background-color: orange;
}

.table-div {
    height: 70vh;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

}

.small-rounded{
    border-radius: 20px;
}

.small-rounded-top{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.book-promo-banner {
    border-radius: 5px;
    background-color: #4DB1B5;
    font-family: "Poetsen One";
}

.two-column-layout {
    display: flex;
    justify-content: space-between;
}

.column {
    flex: 1;
    padding: 20px;
}

.column img {
    width: 100%;
    height: auto;
}

.image-text-page {
    z-index: 11;
}

.custom-grid-col {
    flex: 0 0 20%; /* Make each column take up 20% of the row (100/5 = 20) */
    max-width: 20%; /* Ensure the column doesn't exceed 20% */
}



/*Text-color*/
.text-gold{
    text-align: center;
    color: #E0BB7F;
}

.text-navy-blue{
    text-align: center;
    color: #091E3B;
}






/*Text*/

.oswald-font{
    font-family: "Oswald";

}
.poetsen-font{
    font-family: "Poetsen One";

}

.blueberry-font{
    text-align: center;
    font-family: "blueberry";
}

.agbalumo-font{
    text-align: center;
    font-family: "agbalumo";
}

.gagalin-font{
    text-align: center;
    font-family: "gagalin";
}

.landing-main-text{
    
    margin-top: 25%;
}

@media (min-width: 768px) {
    .landing-main-text {
        margin-top: 13%;
    }
}

@media (min-width: 992px) {
    .landing-main-text {
        margin-top: 10%;
    }
}

@media (min-width: 1170px) {
    .landing-main-text {
        margin-top: 8%;
    }
}

.negative-20-margin{
    margin-bottom: -20px;
}

.negative-20-margin-top{
    margin-top: -20px;
}

.negative-10-margin-top{
    margin-top: -10px;
}

.negative-5-margin-top{
    margin-top: -5px;
}

.negative-2-margin-top {
    margin-top: -2px;
}

.margin-right-20 {
    margin-top: 20px;
}

.banner-text {
    font-family: "Poetsen One";
    background-color: #4DB1B5;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7vh;
    color: white;
    font-size: 2em;
    margin: 0px;
}

@media (min-width: 768px) {
    .banner-text {
        font-family: "Poetsen One";
        background-color: #4DB1B5;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 7vh;
        color: white;
        font-size: 5em;
        margin: 0px;
    }
}

.large-text{
    font-size: large;
    text-align: center;
}


.medium-text{
    font-size: medium;
    text-align: center;
}

.small-text{
    font-size: x-small;
    text-align: center;
}

.sale-item-title {
    color: white;
    text-align: center;
    margin-bottom: 30px;
    font-family: Pacifico;
}

.remove-underlink{
    text-decoration: none;
}

.navy-blue-underline{
    text-decoration: underline;
}

input::placeholder {
    text-align: center;
}

input {
    text-align: center;
}

select {
    text-align: center;  /* Centers the selected option's text */
    text-align-last: center; /* Centers the placeholder or the selected option text in modern browsers */
}

option {
    text-align: center; /* Centers the options' text inside the dropdown */
}






/*Images*/
.full-width-height-image{
    width:100%;
    height: 100%;
}

.three-quarter-width-height-image{
    width:75%;
    height: 75%;
}

.same-line-img-container{
    display: flex;
    alignItems: center;
}

.tick-image{
    max-width: 20px;
    max-height: 20px;
}


.grey-image{
    filter: grayscale(100%);
}








/*Buttons*/
.primary-button {
    /*background-color: #ffd289;*/
    background-color: dodgerblue;

    /*color: #091E3B;*/
    color: white;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    text-align: center;


    -webkit-box-shadow: 0px 0px 10px 0px #091E3B;
    -moz-box-shadow: 0px 0px 10px 0px #091E3B;
    box-shadow: 0px 0px 10px 0px #091E3B;
}

.primary-button:hover {
    background-color: yellowgreen;
}


.secondary-button {
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: normal;
    background-color: yellowgreen ;
    border-color: transparent;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    font-weight: bold;
    font-size: large;
    /*margin-top: 20px;*/
    /*margin-bottom: 20px;*/
    /*margin-right: 10px;*/

    

    /*-webkit-box-shadow: 0px 0px 10px 0px #091E3B;*/
    /*-moz-box-shadow: 0px 0px 10px 0px #091E3B;*/
    /*box-shadow: 0px 0px 10px 0px #091E3B;*/
}

.secondary-button:hover,
.secondary-button:active{
    background-color: #ffd289;
    color: #091E3B;
}



.end-button {
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: normal;
    background-color: #4CB1B5 ;
    border-color: transparent;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;



    /*-webkit-box-shadow: 0px 0px 10px 0px #091E3B;*/
    /*-moz-box-shadow: 0px 0px 10px 0px #091E3B;*/
    /*box-shadow: 0px 0px 10px 0px #091E3B;*/
}

.end-button:hover {
    background-color: #ffd289;
}


.gradient-button {
    background: linear-gradient(135deg,  lightcyan, lightseagreen);
    border-color: transparent;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bolder;
    font-family: Oswald;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    height: 40px;


    /*-webkit-box-shadow: 0px 0px 30px 0px #091E3B;*/
    /*-moz-box-shadow: 0px 0px 30px 0px #091E3B;*/
    /*box-shadow: 0px 0px 30px 0px #091E3B;*/
}

.gradient-button:hover {
    background: gold;
}


/* Book */



.img-button{
    background-color: transparent;
    outline-color: transparent;
    border: 0;
    outline: none;
}


/* Library */

:is(.comingsoon-item-button, .read-item-button, .refresh-item-button, .sale-item-button) {
    color: white;
    border-radius: 30px;
    font-weight: bolder;
    font-size: 0.9em;
    font-family: "Oswald";
    height: 40px;
    border-color: transparent;
}

.comingsoon-item-button {
    background-color: #6c757d;
}

.read-item-button {
    background-color: yellowgreen;
    color: #091E3B;
}

.refresh-item-button {
    background-color: #6c757d;
}

.sale-item-button {
    background-color: #0074D9;
}

.img-button:hover {
    box-shadow: 0px 0px 10px 5px yellowgreen;
    background-color: yellowgreen;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}



.carousel-control-prev-icon{
    background-image: url('https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/previous-arrow.png'); /* Path to your custom image */
    background-size: cover; /* Adjust as needed */
    
}


.carousel-control-next-icon {
    color: yellow;
    background-color: transparent;
            /*#091E3B; !* Change the color as needed *!*/
    /*border-radius: 30px;*/
    /*padding: 30px;*/
    background-image: url('https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/next-arrow.png'); /* Path to your custom image */
    background-size: cover; /* Adjust as needed */
}



.carousel-indicators li {
    background-color: #000000; /* Change to any dark color you prefer */
}

.carousel-indicators .active {
    background-color: black; /* Slightly different color for the active indicator */
}


/* animations*/
@keyframes pulsate {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.pulsating {
    animation: pulsate 1s ease infinite;
}




/* Forgot Password */
.forgotten-password-card {
    width: 40%;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
  }
  
  /* For devices with width less than 600px */
  @media (max-width: 600px) {
    .forgotten-password-card {
      width: 90%;
    }
  }


/*graphs and bars */
.progressBarContainer {
    width: 50px;
    height: 50px;
}

/*Book Reader*/
.full-screen-carousel .carousel-item {
    height: 100vh;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Change the transition duration here */

}

.full-screen-carousel img {
    height: 100vh;
    object-fit: cover;
}

.carousel-container {
    position: relative;
}
/**/

.carousel-control-prev,
.carousel-control-next {
    top: 50%;
    transform: translateY(-50%);
    width: 5%; /* Adjust this value as needed */
}

.carousel-control-prev {
    left: 5px; /* Adjust this value to move the button further left */
}

.carousel-control-next {
    right: 5px; /* Adjust this value to move the button further right */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0); /* Default arrow background color */
    background-size: 100%, 100%;
    border-radius: 50%;
    width: 50px; /* Adjust arrow size as needed */
    height: 50px; /* Adjust arrow size as needed */
}


.white-image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -999;
}

.final-page-book-message {
    /*position: absolute;*/
    /*top: 85%;          !* Position the top edge of the element at 60% of the screen height *!*/
    /*left: 50%;         !* Position the left edge of the element at 50% of the screen width *!*/
    /*transform: translate(-50%, -50%); !* Shift the element to the left and up by 50% of its own width and height *!*/
    /*text-align: center; !* Optional: Center the text inside the div *!*/
    /*z-index: 999999999;*/
    color: #091E3B;
    font-family: Clap Hand;
}

.final-page-book {
    /*position: absolute;*/
    /*top: 50%;          !* Position the top edge of the element at 60% of the screen height *!*/
    /*left: 50%;         !* Position the left edge of the element at 50% of the screen width *!*/
    /*transform: translate(-50%, -50%); !* Shift the element to the left and up by 50% of its own width and height *!*/
    /*text-align: center; !* Optional: Center the text inside the div *!*/
    /*z-index: 999999999;*/
}

.book-box{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    border-radius: 30px;
    /*padding: 10px;*/
    


}

.confetti-depth{
    z-index: 9999999999;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 10px;               
    width: 100%;
    background-color: #ffd289; 
    transform-origin: 0%;       
    z-index: 1000;             
}


.cool-button{
    position: absolute;
    top: 63%;  
    left: 52%;
    transform: translateX(-50%);
}

@media (min-width: 1200px) {
    .cool-button {
        position: absolute;
        top: 86%;
        left: 52%;
        transform: translateX(-50%);
    }
}

.infinite-reviews-container {
    overflow: hidden; /* Hides the overflow */
    /*white-space: nowrap;*/
    width: 100%; /* Adjust according to your container's width */
    background-color: #091E3B;

}


.reviews-wrapper {
    display: flex;
    animation: scroll 30s linear infinite ;
    pointer-events: none;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-1000px); /* Scroll by the width of one set of reviews */
    }
}


.landing-container{
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-image: url('https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/hero_background.gif');
    margin-top: -160px;
    background-size: cover;
    background-position: center;
}

.print-container{
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #DCF5FF;
    
    
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.faq-container{
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #DCF5FF;


    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}


.book-reader-div{
    z-index: 5;
}



.image-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Easing transition */
}

.image-animation.active {
    opacity: 1;
}


/*book cover*/
.book-cover-image-container {
    position: relative;
    /*width: 400px;  !* Image width *!*/
    /*height: 1200px; !* Image height *!*/
}



/* Overlay text styling */
.book-cover-overlay-text {
    position: absolute;
    top: 35%;         /* Vertically center */
    left: 50%;        /* Position at 60% of the image width */
    transform: translate(-50%, -50%); /* Adjust for proper centering */
    font-family: 'Rancho';  /* Apply Castiloga font */
    font-size: 2.5em;  /* Adjust text size as needed */
    font-weight: bold;
    color: #102E57;     /* Text color */
    text-align: center;
    line-height: 0.95;
    letter-spacing: 1px;
    /*width: 70%;*/
    text-shadow: -1px -1px 0 white,
        /* Top left */ 1px -1px 0 white,
        /* Top right */ -1px 1px 0 white,
        /* Bottom left */ 1px 1px 0 white;
}


/* Overlay text styling */
@media (min-width: 1200px) {
    .book-cover-overlay-text {
        position: absolute;
        top: 35%; /* Vertically center */
        left: 50%; /* Position at 60% of the image width */
        transform: translate(-50%, -50%); /* Adjust for proper centering */
        font-family: 'Rancho'; /* Apply Castiloga font */
        font-size: 3em; /* Adjust text size as needed */
        font-weight: bold;
        color: #102E57; /* Text color */
        text-align: center;
        line-height: 0.95;
        letter-spacing: 2px;

    }

    /* Overlay text styling */
    @media (min-width: 1600px) {
        .book-cover-overlay-text {
            position: absolute;
            top: 35%; /* Vertically center */
            left: 50%; /* Position at 60% of the image width */
            transform: translate(-50%, -50%); /* Adjust for proper centering */
            font-family: 'Rancho'; /* Apply Castiloga font */
            font-size: 5em; /* Adjust text size as needed */
            font-weight: bold;
            color: #102E57; /* Text color */
            text-align: center;
            line-height: 0.95;
            letter-spacing: 2px;
        }
    }
}

.glowing-button {
    /*padding: 15px 30px;*/
    font-size: 18px;
    /*color: #091E3B;;*/
    color: floralwhite;
    font-family: 'Oswald';
    background-color: #004AAD; /* Inner button color */
    border: 4px solid transparent; /* Placeholder for outline */
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    outline: none;
}

.glowing-button::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 30px;
    background: linear-gradient(
            45deg,
            red,
            orange,
            yellow,
            green,
            blue,
            indigo,
            violet
    );
    z-index: -1;
    filter: blur(2px);
    animation: glowing 2s linear infinite;
}

@keyframes glowing {
    0% {
        filter: hue-rotate(0deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}

/* Ensure the pseudo-element stays behind the button */
.glowing-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius:30px;
    background-color: royalblue;
    z-index: -1;
}

.glowing-button:hover {
    color: white; /* Change color on hover */
}

.glowing-button:hover::after {
    background-color: darkorange; /* Change color on hover */
    color: white; /* Change color on hover */
}






