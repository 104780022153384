/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.cdnfonts.com/css/gagalin');
@import url('https://fonts.cdnfonts.com/css/amaranth');
@import url('https://fonts.cdnfonts.com/css/agbalumo');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.cdnfonts.com/css/poetsen-one');
@import url('https://fonts.cdnfonts.com/css/blueberry');


/* Sentry Userfeedback Form */
:root {
    --background: 'black'; /* Default background color */
    --dialog-background: #F2F0EF; /* Default dialog background color */
    --button-primary-background: yellowgreen;
    /* --button-primary-color: #FFFFFF; */
    /* --button-background: yellowgreen; */
    --input-border: black;
    --button-background: #B0B0B0; /* Soft gray for cancel */
    --button-color: #FFFFFF; /* White text for readability */
  }
  



a {
    color: #0366d6;
}

code {
    color: #E01A76;
}



html, body {
    /*background: #674ea7ff;*/
    /*background-image: url('purple_background.png');*/
    background: red;
    /*background-image: url('bg-non-text.png');*/
    /*background-image: url('dynamic_hero.gif');*/
    background-size: cover;
    /* Background image is centered vertically and horizontally at all times */
    background-position: center center;
    /* Background image doesn’t tile */
    background-repeat: no-repeat;
    /* Background image is fixed in the viewport so that it doesn’t move when the content’s height is greater than the image’s height */
    background-attachment: fixed; /* This is what makes the background image rescale based on the container’s size */
    background-size: cover; /* Set a background color that will be displayed while the background image is loading */
    background-color: #091E3B;
    width: 100vw;
}


.basic-text {
    text-align: center;
}


/*.btn-primary {*/
/*    color: #fff;*/
/*    !*background-color: #1b6ec2;*!*/
/*    !*border-color: #1861ac;*!*/
/*    background-color: greenyellow;*/
/*    color: #091E3B;*/
/*    border-radius: 30px;*/
/*}*/

.my-button {
    margin-top: 20px;
    background: blueviolet;
    border-radius: 30px;
    font-weight: bold;
    background-color: greenyellow;
    color: #091E3B;
}

@media (min-width: 300px) {
    h1 {
        color: white;
        font-family: "gagalin";
        font-weight: bold;
        /*text-shadow: 2px 4px 4px rgba(0, 0, 0, 1);*/
        /*font-size: 48px;*/
        font-size: 28px;
        margin-top: 40px;
    }
}

@media (min-width: 768px) {
    h1 {
        color: white;
        font-family: "gagalin";
        font-weight: bold;
        /*text-shadow: 2px 4px 4px rgba(0, 0, 0, 1);*/
        /*font-size: 48px;*/
        font-size: 28px;
    }
}

@media (min-width: 1000px) {
    h1 {
        color: white;
        font-family: "gagalin";
        font-weight: bold;
        /*text-shadow: 2px 4px 4px rgba(0, 0, 0, 1);*/
        /*font-size: 48px;*/
        font-size: 48px;
    }
}

@media (min-width: 1300px) {
    h1 {
        color: white;
        font-family: "gagalin";
        font-weight: bold;
        /*text-shadow: 2px 4px 4px rgba(0, 0, 0, 1);*/
        /*font-size: 48px;*/
        font-size: 52px;
    }
}






@media (min-width: 300px) {
    .sale-text-heading {
        font-family: "gagalin";
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 34px;
        letter-spacing: 1px;
    }
}

@media (min-width: 768px) {
    .sale-text-heading {
        font-family: "gagalin";
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 34px;
        letter-spacing: 1px;
    }
}

@media (min-width: 1000px) {
    .sale-text-heading {
        color: #091E3B;
        font-family: "gagalin";
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 36px;
        letter-spacing: 1px;
    }
}

@media (min-width: 1300px) {
    .sale-text-heading {
        color: #091E3B;
        font-family: "gagalin";
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 38px;
        /*letter-spacing: 1px;*/
    }
}


.sale-banner-heading-new {
    color: #091E3B;
    font-family: "Poetsen One";
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;

    margin-top: 0px;
    margin-bottom: 0px;
    font-size: x-large;
    letter-spacing: 1px;
    
}

@media (min-width: 300px) {
    .sale-banner-heading {
        color: #091E3B;
        font-family: "Poetsen One";
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;

        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
        letter-spacing: 1px;
    }
}

@media (min-width: 768px) {
    .sale-banner-heading {
        color: #091E3B;
        font-family: "Poetsen One";
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 24px;
        letter-spacing: 1px;
    }
}

@media (min-width: 1000px) {
    .sale-banner-heading {
        color: #091E3B;
        font-family: "Poetsen One";
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 26px;
        letter-spacing: 1px;
    }
}

@media (min-width: 1300px) {
    .sale-banner-heading {
        color: #091E3B;
        font-family: "Poetsen One";
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: -10px;
        margin-bottom: 0px;
        font-size: 28px;
        /*letter-spacing: 1px;*/
    }
}


.desc-text {
    color: white;
    text-align: center;

}


.main {
    margin: auto;
    width: 50%;
    text-align: center;
    margin-top: 20%;
}

.mains {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

section-old {
    /*border: 10px solid grey;*/
    height: 90vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

section {
    /*border: 10px solid grey;*/
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}


/*.cont {*/
/*    !*border: 10px solid blue;*!*/
/*    !*max-width: 1000px;*!*/
/*    !*margin: auto;*!*/
/*    !*height: 100vh;*!*/
/*    !*display: flex;*!*/
/*    !*align-items: center;*!*/
/*    !*justify-content: center;*!*/
/*    text-align: center;*/
/*    width: 100%;*/
/*}*/

.div-button {
    margin-top: 40px;
    background-color: greenyellow;
    color: #091E3B;
}

.countdown-timer {
    width: 100%;
    color: white;
    text-align: center;
    /*font-weight: bold;*/
    margin-top: 0px;
    padding-top: 1px;
    margin-bottom: 5px;
    background: navajowhite;
    /*background: #DD580E;*/
    /*background-color: #E0BB7F;*/
    font-family: "Oswald";
    textDecoration: none;

}

@media (min-width: 700px) {
    .email-form {
        /*width: 400px;*/
        padding: 10px;
        /*border-top-left-radius: 10px;*/
        /*border-bottom-left-radius: 10px;*/
        border-radius: 20px;
        font-family: gagalin;
        font-size: 20px;
        margin-top: 10px;

    }
}

@media (min-width: 300px) {
    .email-form {
        /*width: 400px;*/
        padding: 10px;
        /*border-top-left-radius: 10px;*/
        /*border-bottom-left-radius: 10px;*/
        border-radius: 20px;
        font-family: gagalin;
        font-size: 14px;
        margin-top: 10px;

    }
}

.orange-btn {
    /*border-top-right-radius: 10px;*/
    /*border-bottom-right-radius: 10px;*/

    margin-left: 20px;
    border-radius: 20px;
    /*background-color: darkorange;*/
    background-color: greenyellow;

    align-items: center;
    font-weight: bold;
    font-family: gagalin;
    font-size: 14px;
    padding: 10px;
    color: #091E3B;
}


@media (min-width: 700px) {
    .custom-btn {
        /*border-top-right-radius: 10px;*/
        /*border-bottom-right-radius: 10px;*/

        margin-left: 20px;
        border-radius: 20px;
        background-color: #5bccfaff;
        align-items: center;
        font-weight: bold;
        font-family: gagalin;
        font-size: 30px;
        padding: 10px;
        background-color: greenyellow;
        color: #091E3B;
    }
}

@media (min-width: 300px) {
    .custom-btn {
        /*border-top-right-radius: 10px;*/
        /*border-bottom-right-radius: 10px;*/

        margin-left: 20px;
        border-radius: 20px;
        background-color: #5bccfaff;
        align-items: center;
        font-weight: bold;
        font-family: gagalin;
        font-size: 14px;
        padding: 10px;
        background-color: greenyellow;
        color: #091E3B;

    }
}


.promo-image {
    border-radius: 30px;
    margin-bottom: 20px;
}

.cont {
    /*border: 10px solid red;*/

}


.book-reader {
    /*border: 50px solid black;*/
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: auto;
    height: 70vh;
    margin-bottom: 40px;

}

.page-text {
    color: white;
    font-weight: bold;
}

.guide {
    border-radius: 30px;
    margin-bottom: 20px;
}

.next-btn {
    padding: 20px;
    border-radius: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: greenyellow;
    color: #091E3B;
}

.custom-title-class {
    /*color: white;*/
    color: #091E3B !important;
}

.custom-content-class {
    color: blue !important;
}

@media (min-width: 300px) {
    .book-padding {
        margin-right: 20px;
        margin-bottom: 10px;
    }
}

@media (min-width: 700px) {
    .book-padding {
        margin-right: 50px;
        margin-bottom: 20px;
    }
}

.book-reader-text {
    text-align: center;
}

@media (min-width: 300px) {
    .book-page {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (min-width: 700px) {
    .book-page {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }
}

.book-cover {
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.8);
}

.book-cover:hover {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 30px 0px yellowgreen;
    -moz-box-shadow: 0px 0px 30px 0px yellowgreen;
    box-shadow: 0px 0px 30px 0px yellowgreen;
    background-color: greenyellow;

    /*animation: shake 0.5s;*/
    /*animation-iteration-count: infinite;*/
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.image-container {
    position: relative;
}

.image-container img {
    display: block;
    width: 100%;
    height: auto;
}

.image-container button {
    position: absolute;
    top: 90%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.sale-image {
    border-radius: 0px;
    margin-bottom: 20px;
}

.sale-item {
    align-items: center;
    text-align: center;
    padding: 10px;
    background-color: white;
    /*margin-right: 10px;*/
    border-color: white;
    border-radius: 20px;
    text-align: center;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.8);
    /*height: 10%;*/
    /*height: 80vh;*/

}



.login-text {
    color: #091E3B;
    font-family: Oswald;
    text-align: center;
}

.login-input {
    margin-bottom: 20px;
    background-color: #D9D9D9;
    border-color: #091E3B;
    border-width: 2px;
    font-family: Oswald;
    text-align: start;
    border-radius: 10px;
    padding: 5px;
    text-align: center;


}



.login-column {
    height: 40vh;
    border-radius: 30px;
    padding: 10px;
    background-color: white;
}

.login-box {
    text-align: center;
    justify-content: center;
    align-content: center;
}

.login-text-box {
    width: 80%;
    /*background-color: greenyellow;*/
    display: inline-block;
    margin: 0 auto;
    
}


.login-column2 {
    border-radius: 30px;
    color: red;
    /*background-color: red;*/
    width: 15%;
    height: 60vh;
    padding: 10px;

}

.login-header {
    font-family: gagalin;
    color: #091E3B;
    border-bottom: 3px solid #FFD289;
    text-align: center;
}

.character-header {
    font-family: gagalin;
    color: #091E3B;
    /*border-bottom: 3px solid #FFD289;*/
    text-align: center;
}


.login-image {
    border-radius: 20px;
}

.login-button {
    background-color: #FFD289;
    font-family: Oswald;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 12px;
}


@media (min-width: 300px) {
    h2 {
        color: #091E3B;
        font-family: "gagalin";
        font-weight: bold;
        /*font-size: 48px;*/
        font-size: 28px;
        margin-top: 40px;
    }
}

@media (min-width: 768px) {
    h2 {
        color: #091E3B;
        font-family: "gagalin";
        font-weight: bold;
        /*font-size: 48px;*/
        font-size: 36px;
    }
}

@media (min-width: 1000px) {
    h2 {
        color: #091E3B;
        font-family: "gagalin";
        font-weight: bold;
        /*font-size: 48px;*/
        font-size: 46px;
    }
}

@media (min-width: 1300px) {
    h2 {
        color: #091E3B;
        font-family: "gagalin";
        font-weight: bold;
        font-size: 64px;
    }
}

.toast-position {
    top: 10rem !important;
}

.buy-now-button {
    color: #091E3B;
    background-color: greenyellow;
    font-family: Oswald;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 30px;
    padding: 10px;
}

.buy-now-button :hover {
    background-color: lightblue;
}


.sale-item-button-old {
    color: #091E3B;
    background-color: #FFD289;
    border-radius: 10px;
    font-weight: bold;
    font-family: "gagalin";
    padding: 10px;
    margin-bottom: 10px;
}




.sale-item-not-available-button {
    color: #091E3B;
    background-color: darkgrey;
    border-radius: 10px;
    font-weight: bold;
    font-family: "gagalin";
    padding: 10px;
    margin-bottom: 10px;
}


.sale-item-text {
    font-family: "Poetsen One";
    /*text-align: start;*/
}

.yellow-line {
    width: 80%;
    height: 3px;
    background-color: #FFD289;
    margin: 0 auto;
    margin-bottom: 5px;
}

.sale-item-banner {
    color: white;
    text-align: center;
    margin-bottom: 20px;
}



.sale-item-description {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-family: Oswald;
}

.sale-old-price {
    /*margin-bottom: 5px;*/
    display: inline-block;

    text-decoration: line-through;
    color: red;

}

.sale-hot-banner {
    padding: 5vh;
    background-color: #FF3131;
    margin-bottom: -30px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-right: 10px;

}

.sale-promo-deal {
    /*margin-top: 40px;*/
    color: white;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #FF3131;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    top: 15px;
    padding-bottom: 25px;
    margin-bottom: -20px;
    /*width: 97%;*/
}

.custom-list {
    margin-top: 5px;
    text-align: start;
    list-style-type: none;
    width: 100%;
    /*margin-bottom: -50px;*/
    /*background-color: red;*/
}

.bullet-image {
    /*display: inline-block;*/
    width: 20px; /* Adjust the width of the image as needed */
    height: 20px; /* Adjust the height of the image as needed */
    /*background-image: url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/tick.png"); !* Replace with your image URL *!*/
    /*background-size: cover;*/
    /*margin-right: 10px; !* Adjust the margin between the image and text as needed *!*/
}

.list-item{
    margin-top: 5px;
    font-family: "Poetsen One";
    font-weight: lighter;
    font-size: small;
    margin-right: 0px;
}


.logged-in-box {
    align-items: center;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 100vh;
}

.logged-in-title {
    text-decoration: underline;
    font-family: gagalin;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 20px;

}

.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

}

.error-page-box {
    padding: 10px;
    border-radius: 20px;
    background-color: white;
    height: 95%;
    text-align: center;
}

.error-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.error-page-heading {
    font-family: gagalin;
    color: #091E3B;
}

.error-page-image {
    margin-bottom: 5px;
    margin-top: 5px;

}

.error-page-text {
    margin-top: 5px;
    font-family: Oswald;
    width: 80%;
    font-weight: bold;
}

.home-box {
    align-items: center;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 50%;
}

.login-btn {
    display: flex;
    justify-content: flex-end;
}

.login-book {
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
    /*margin-right: 5px;*/
    margin: 20px;
    width: 70%;
    border-radius: 10px;
}

.login-book-div {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: center;
    padding:20px;
}

.login-book-read {
    display: flex;
    position: relative;
    display: inline-block;
    align-content: center;
    justify-content: center;
}

.home-read-button {
    display: flex;
    position: relative;
    align-content: center;
    justify-content: center;
}

.new-release-image {
    width: 100%;
    border-radius: 20px;
    margin-top: 20px;
}

.book-selector-heading {
    text-align: center;
}

.product-text {
    width: 90%;
    text-align: center;
}

.product-text-box {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: center;
}

.input-big {
    /*width: 50px;*/

}

.confirm-button{
    color: white !important;
    border-radius: 30px !important;
    font-family: Oswald !important;
    font-weight: bold !important;
    outline-color: rgba(0, 0, 0, 0.0) !important;
    width: 300px;
}

.confirm-button:hover{
    color: #091E3B !important;
    background-color: yellowgreen !important;
}

.popup-style{
    border-radius: 30px !important;
    background-color: #091E3B !important;
    color: #091E3B !important;
}

.swal-text{
    color: #091E3B !important;
}

.bah {
    display: flex;
    flex-direction: column;
}


.checkout-card {
    /*flex: 1;*/
    background-color: red;
    border-radius: 30px;
    background-color: white;
    height: 80vh;
    margin-bottom: 50px;
    /*border: 2px solid #DAF5FF;*/
}

.buy-card-slider-card {
    border-radius: 20px;
    height: 100%;
    /*background-color: darkorange;*/

}

.selected-border{
    border: 5px solid yellowgreen;
    border-radius: 30px;
    background-color: #E9FFC2;
}

.home-button {
    background-color: #ffd289;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;

    -webkit-box-shadow: 0px 0px 30px 0px #091E3B;
    -moz-box-shadow: 0px 0px 30px 0px #091E3B;
    box-shadow: 0px 0px 30px 0px #091E3B;
}

.home-button:hover {
    background-color: yellowgreen;
}


.home-button-secondary {
    background-color: yellowgreen ;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;

    -webkit-box-shadow: 0px 0px 30px 0px #091E3B;
    -moz-box-shadow: 0px 0px 30px 0px #091E3B;
    box-shadow: 0px 0px 30px 0px #091E3B;
}

.home-button-secondary:hover {
    background-color: #ffd289;
}



.home-button-tertiary {
    background-color: #F79748 ;
    color: #091E3B;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;

    -webkit-box-shadow: 0px 0px 5px 0px #000000;
    -moz-box-shadow: 0px 0px 5px 0px #091E3B;
    box-shadow: 0px 0px 5px 0px #091E3B;
}

.home-button-tertiary:hover {
    background-color: yellowgreen;
}



.home-button-quad {
    background-color: #4DB1B5 ;
    color: #FFF;
    border-radius: 30px;
    font-weight: bold;
    font-family: Oswald;
    margin-top: 20px;
    margin-bottom: 20px;

    -webkit-box-shadow: 0px 0px 5px 0px #000000;
    -moz-box-shadow: 0px 0px 5px 0px #091E3B;
    box-shadow: 0px 0px 5px 0px #091E3B;
}

.home-button-quad:hover {
    background-color: yellowgreen;
}

.zoom-out-container{
    transform: scale(0.8); /* Adjust the value to control the level of zoom-out */
    transform-origin: center center;
}

.discount-box{
    margin-top: 10px;
    width: 80%;
    /*height: 30px;*/
    border-radius: 10px;
    font-family: "Poetsen One";
    color: white;
    align-content: center;
    justify-content: center;
    font-size: large;
}


.card-image{
    margin-top: -20px;

}

.str-image{
    margin-top: 20px;
}



.slick-slide {
    margin-right: 10px;
    margin-left: 10px;


    max-width: calc(33.33% - 20px);
}



.carousel-container {
    width: 100%;
    margin-bottom: 10px;
    /* Add other styles as needed */
}


.full-width-image {
    max-width:100%;  /* added */
    object-fit: cover; /* Ensure the image covers the entire container */
    overflow: hidden; /* To hide any overflowed content */
    /* Center the image both horizontally and vertically */
    display: flex;
    align-items: center;
    justify-content: center;
}




.landing_div_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85vh;
    color: white;
    font-size: 2em;
    object-fit: cover;
    margin: 0px;
    padding: 0px;
}

@media (min-width: 768px) {
    .landing_div_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 93vh;
        color: white;
        font-size: 2em;
        object-fit: cover;
        margin: 0px;
        padding: 0px;
    }
}

.mobile-landing-product-offer{
    height: 100%;
    background-color: #D9F1F2;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 1.5em;
    margin: 0px;
    padding: 0px;
}


.landing-product-offer{
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 1.5em;
    margin: 0px;
    padding: 0px;
    background-color: #D9F1F2;


}



.landing_three_item_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 2em;
    object-fit: cover;
    margin: 0px;
    padding: 0px;
    background-color: #091E3B;
}


.boxer-secondary {

    width: 100%;
    height: 90vh;
    color: white;
    font-size: 2em;
    object-fit: cover;
}

.text-boxer {
    background-color: #4DB1B5;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7vh;
    color: white;
    font-size: 2em;
}

@media (min-width: 768px) {
    .text-boxer {
        background-color: #4DB1B5;
        /*display: flex;*/
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 7vh;
        color: white;
        font-size: 5em;

    }
}

.landing_primary_div {
    justify-content: center;
    align-items: center;
    background-image: url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/dynamic_hero.gif");
    background-size: cover;
    background-position: center;
    margin-top: -160px;
    width: 100vw;

}

.large_screen_landing_second {
    background-color: #F3F1EC;
    justify-content: center;
    align-items: center;
    background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/landing_sec_bed_but_different.png");
    background-size: cover;
    background-position: center;
    width: 100vw;
}


.large_screen_landing_gift {
    background-color: #F3F1EC;
    justify-content: center;
    align-items: center;
    background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/d_landing_perfect_gift.png");
    background-size: cover;
    background-position: center;
    width: 100vw;
}

.mobile_screen_landing_gift {
    background-color: #F3F1EC;
    justify-content: center;
    align-items: center;
    background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/m_landing_perfect_gift.png");
    background-size: cover;
    background-position: center;
    width: 100vw;
}

.big{
    height: 100%;
    width: 100%;
}

.green {
    background-color: red;
    width: 100vw;
    
    margin: 0;
    padding: 0;
}

.fuschia {
    background-color: purple;
    width: 100vw;


}

.yellow {
    background-color: yellow;
}

.pink {
    background-color: #091E3B;
    width: 100vw;
    
}

.black {
    background-color: black;
}



.second-image-font {
    /*font-family: blueberry;*/
    font-family: "agbalumo";
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin: 0px;
}

.aqua{
    background-color: #4DB1B5;
    width: 100vw;
}

.header-custom{
    width: 100vw;
}


.second-text{
    margin-top: -100px;
}


.square-image{
    width:100%;
    height: 100%;
}

square-image-container {
    margin: 0;
    padding: 0;
    overflow: hidden;

}

.btn-pos-mid{
    margin-bottom: 10rem;
}

.product-image{
    width: 70%;
}



.mobile-landing-second{
    background-color: #F3F1EC;
    justify-content: center;
    align-items: center;
    background-image: url("https://oststorage.blob.core.windows.net/ostpstrblb/app/m_landing_sec_bed_but_different.png");
    background-size: cover;
    background-position: top;
}

.mobile-landing-product{
    background-color: #D9F1F2;
    justify-content: center;
    align-items: center;
}
.book-cover-container{
    height: 40%;
    width: 100%;
}

book-cover{
    height: 40%;
}

.review-card{
    background-color: white;
    height: 25vh;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    width:100%;
    border-color: #091E3B;
}

@media (min-width: 768px) {
    .review-card {
        background-color: white;
        height: 22vh;
        /*display: flex;*/
        align-items: center;
        justify-content: center;
        width:100%;
        margin-bottom: 10px;

    }
}


.swiper-review-card{
    background-color: white;
    height: 70vh;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    width:100%;
    border-color: #091E3B;
}

@media (min-width: 768px) {
    .swiper-review-card {
        background-color: white;
        height: 40vh;
        /*display: flex;*/
        align-items: center;
        justify-content: center;
        width:100%;
        margin-bottom: 10px;

    }
}

.review-slider{
    /*height: 40vh;*/

}

.checked {
    color: navy;
}

.card {
    display: grid;
    place-items: center;
}

.home-text{
    margin-top: -100px;
}

.line {
    border-bottom: 3px solid black;
}

.icon{
    align-content: center;
    justify-content: center;
}

.custom-accordion{
    border-radius: 30px;
    
}

